// src/components/ProductGrid.js
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ProductGrid = ({ products, onShowDetails }) => {
    return (
        <Row>
            {products.map(product => (
                <Col key={product.id} sm={6} md={4} lg={3} className="mb-4">
                    <div className="product-grid-item" onClick={() => onShowDetails(product)}>
                        <div className="product-image">
                            <img src={product.cover_img} alt={product.item_title} className="img-fluid" />
                        </div>
                        <div className="product-details">
                            <h5>{product.item_title}</h5>
                            <p>{product.unit_price.toLocaleString('en-US', {style: 'currency', currency: 'LKR'})}</p>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

ProductGrid.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        item_id: PropTypes.number.isRequired,
        item_title: PropTypes.string.isRequired,
        unit_price: PropTypes.string.isRequired,
        cover_img: PropTypes.string.isRequired
    })).isRequired,
    onShowDetails: PropTypes.func.isRequired
};

export default ProductGrid;
