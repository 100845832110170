// src/components/ProductModal.js
import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import '../assets/scss/ProductModal.scss'; // Import custom styles


const ProductModal = ({ show, onHide, product }) => (
    <Modal show={show} onHide={onHide} dialogClassName="modal-large">
        <Modal.Header closeButton className="modal-header">
            <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            {product && (
                <div className="product-details">
                    <Row>
                        <Col md={4} className="image-col">
                            <img src={product.cover_img} alt={product.item_title} className="product-image" />
                        </Col>
                        <Col md={8}>
                            <h4 className={"heading"}>{product.item_title}</h4>
                            <p className="price">{product.unit_price.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'LKR'
                            })}</p>

                            <Row>
                                <Col md={4} className="info-col">
                                    <p><strong>Category:</strong> {product.category}</p>
                                    <p><strong>ISBN:</strong> {product.isbn}</p>
                                    <p><strong>EAN13:</strong> {product.ean13}</p>
                                    <p><strong>Authors:</strong> {product.authors}</p>
                                    <p><strong>Publisher:</strong> {product.mtanufacture}</p>
                                    <p><strong>Last print:</strong> {product.last_print}</p>
                                </Col>
                                <Col md={4} className="info-col">
                                    <p><strong>Department:</strong> {product.department}</p>
                                    <p><strong>Weight:</strong> {product.weight} g</p>
                                    <p><strong>Pack size:</strong> {product.pack_size}</p>
                                    <p><strong>Is group Item:</strong> {product.is_group==1?'yes':'no'}</p>
                                    <p><strong>Language:</strong> {product.language_text}</p>
                                    <p><strong>Edition:</strong> {product.edition}</p>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {(product.branch_info) && (
                        <div className="branch-availability">
                            <Row>
                                <Col>
                                    Re-Level: {product.branch_info.reorder_level}
                                </Col>
                                <Col>
                                    Re-Qty: {product.branch_info.reorder_qty}
                                </Col>
                                <Col>
                                    Rack No: {product.branch_info.rack_no}
                                </Col>
                                <Col>
                                    Rack No2: {product.branch_info.rack_no2}
                                </Col>
                            </Row>
                        </div>
                    )}
                    <div className="branch-availability">
                        <h5>Branch Availability</h5>
                        <div className="branch-list">
                            {product.branches.map((branch, index) => (
                                <div key={index} className="branch-item">
                                    <p><strong>{branch.branch}:</strong> {branch.qty > 0 ? 'Available' : 'Out of Stock'}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <Button variant="secondary" onClick={onHide}>Close</Button>
        </Modal.Footer>
    </Modal>
);

export default ProductModal;