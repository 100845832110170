// src/components/Navbar.js
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const NavBar = ({ selectedBranch, onSearchToggle, onAdvancedSearchToggle, onBranchChange }) => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand href="#" style={{backgroundColor: 'white', borderRadius:'10px',padding:'5px'}}>
                <img src="/logo.png" alt="Logo" style={{ height: '40px' }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto d-flex align-items-center">
                    <Nav.Link
                        className="text-white"
                        style={{
                            backgroundColor: 'grey',
                            borderRadius: '5px',
                            padding: '5px 10px',
                            height: '36px',
                            marginRight: '10px'
                        }}
                        onClick={onSearchToggle}
                    >
                        Basic Search
                    </Nav.Link>
                    <Nav.Link
                        className="text-white"
                        style={{
                            backgroundColor: 'grey',
                            borderRadius: '5px',
                            padding: '5px 10px',
                            height: '36px',
                            marginRight: '10px'
                        }}
                        onClick={onAdvancedSearchToggle}
                    >
                        Advanced Search
                    </Nav.Link>
                    <Nav.Link
                        className="text-white"
                        style={{
                            backgroundColor: 'grey',
                            borderRadius: '5px',
                            padding: '5px 10px',
                            height: '36px',
                            marginRight: '10px'
                        }}
                        onClick={onBranchChange}
                    >
                        Branch: {selectedBranch.branch}
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
