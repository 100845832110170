// src/components/SearchBar.js
import React from 'react';
import { Form, Button } from 'react-bootstrap';

const SearchBar = ({ onSearch }) => {
    const handleSearch = (e) => {
        e.preventDefault();
        onSearch( 'search=' + e.target.search.value);
    };

    return (
        <Form onSubmit={handleSearch} className="d-flex justify-content-center mb-4">
            <Form.Control type="text" name="search" placeholder="Search products..." />
            <Button type="submit" className="ms-2">Search</Button>
        </Form>
    );
};

export default SearchBar;
