// src/components/ProductTable.js
import React from 'react';
import { Table, Button } from 'react-bootstrap';

const ProductTable = ({ products, onShowDetails }) => (
    <Table striped bordered hover>
        <thead>
        <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Price</th>
            <th>ISBN</th>
            <th>EAN13</th>
            <th>Publisher</th>
            <th>Authors</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {products.map((product) => (
            <tr key={product.item_id}>
                <td>{product.item_id}</td>
                <td>{product.item_title}</td>
                <td>{product.unit_price.toLocaleString('en-US', {style: 'currency', currency: 'LKR'})}</td>
                <td>{product.isbn}</td>
                <td>{product.ean13}</td>
                <td>{product.mtanufacture}</td>
                <td>{product.authors}</td>
                <td>
                    <Button variant="primary" onClick={() => onShowDetails(product)}>View</Button>
                </td>
            </tr>
        ))}
        </tbody>
    </Table>
);

export default ProductTable;
