// src/components/Loader.js
import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    </div>
);

export default Loader;
