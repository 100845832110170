import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const BranchSelection = ({ show, onHide, onSelectBranch }) => {
    const [branches, setBranches] = useState([]);

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const response = await fetch("https://live.jeyaagency.com/api/global/branch/list?"); // Replace with your API endpoint
                const data = await response.json();
                setBranches(data.branches); // Assuming the API returns an object with a 'branches' array
            } catch (error) {
                console.error('Error fetching branches:', error);
            }
        };

        fetchBranches();
    }, []);

    const handleBranchSelect = (e) => {
        e.preventDefault();
        const { branch } = e.target.elements;
        const selectedBranch = branches.find(b => b.id === parseInt(branch.value));
        onSelectBranch(selectedBranch);
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Select Branch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleBranchSelect}>
                    <Form.Group controlId="branch">
                        <Form.Label>Branch</Form.Label>
                        <Form.Control as="select" name="branch">
                            {branches.map((branch) => (
                                <option key={branch.id} value={branch.id}>
                                    {branch.branch}
                                </option>
                            ))}
                        </Form.Control>
                        <br />
                        <br />
                    </Form.Group>
                    <Button type="submit" className="w-100">Select Branch</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default BranchSelection;
