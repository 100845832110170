// src/App.js
import React, { useEffect, useState } from 'react';
import NavBar from './components/Navbar';
import SearchBar from './components/SearchBar';
import AdvancedSearch from './components/AdvancedSearch';
import ProductTable from './components/ProductTable';
import ProductGrid from './components/ProductGrid';
import ProductModal from './components/ProductModal';
import Pagination from './components/Pagination';
import BranchSelection from './components/BranchSelection';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from './components/Loader';
import './assets/scss/custom-bootstrap.scss';

const App = () => {
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [view, setView] = useState('grid'); // 'grid' or 'table'
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [selectedBranch, setSelectedBranch] = useState({});
    const [showBranchSelection, setShowBranchSelection] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const storedBranch = localStorage.getItem('selectedBranch');
        if(storedBranch)
            setSelectedBranch(JSON.parse(storedBranch));
    }, []);
    useEffect(() => {
        if(!searchParams)
        handleSearch(searchParams, currentPage);
    }, [currentPage, searchParams]);


    const handleSearch = async (searchParams, page = 1) => {
        setLoading(true);
        const response = await fetch(`https://live.jeyaagency.com/api/global/search?${searchParams}&page=${page}`);
        const data = await response.json();

        setProducts(data.items.data?data.items.data:[]);
        setCurrentPage(data.items.current_page);
        setTotalPages(data.items.last_page);
        setLoading(false);
    };

    const handleShowDetails = (product) => {
        const getStock = async (searchParams, page = 1) => {
            const response = await fetch(`https://live.jeyaagency.com/api/global/search/stock?id=${product.item_id}&branch_id=${selectedBranch.id}`);
            const data = await response.json();
            try{
                product.branches = data.branch_stock;
                product.branch_info = data.branch_info;
                setSelectedProduct(product);
                setModalShow(true);
            }catch(error){
                console.log(error);
            }
        };
        getStock();

    };

    const handleSearchSubmit = (params) => {
        setSearchParams(params);
        handleSearch(params);
    };

    const handleBranchSelect = (branch) => {
        setSelectedBranch(branch);
        console.log(branch);
        localStorage.setItem('selectedBranch', JSON.stringify(branch));
        setShowBranchSelection(false);
    };

    return (
        <>
            <NavBar
                selectedBranch={selectedBranch}
                onSearchToggle={() => setShowAdvancedSearch(false)}
                onAdvancedSearchToggle={() => setShowAdvancedSearch(true)}
                onBranchChange={() => setShowBranchSelection(true)}
            />
            <Container className="mt-4">
                <Row>
                    <Col className="text-center">
                        {showAdvancedSearch ? (
                            <AdvancedSearch onSearch={handleSearchSubmit} />
                        ) : (
                            <SearchBar onSearch={handleSearchSubmit} />
                        )}
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="d-flex justify-content-end">
                        <div className="btn-group" role="group" aria-label="View Options">
                            <button
                                type="button"
                                className={`btn btn-outline-secondary ${view === 'table' ? 'active' : ''}`}
                                onClick={() => setView('table')}
                            >
                                <i className="bi bi-table"></i> {/* Add table icon */}
                            </button>
                            <button
                                type="button"
                                className={`btn btn-outline-secondary ${view === 'grid' ? 'active' : ''}`}
                                onClick={() => setView('grid')}
                            >
                                <i className="bi bi-grid"></i> {/* Add grid icon */}
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {loading ? (
                            <Loader />
                        ) : (
                            products.length > 0 ? (
                                view === 'table' ? (
                                    <ProductTable products={products} onShowDetails={handleShowDetails} />
                                ) : (
                                    <ProductGrid products={products} onShowDetails={handleShowDetails} />
                                )
                            ) : (
                                <div className="alert alert-danger" role="alert">
                                    No products available to list, please enter keywords and search
                                </div>
                            )
                        )}
                    </Col>
                </Row>
                {products.length > 0 && (
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </Col>
                    </Row>
                )}
            </Container>
            {(selectedProduct) && (<ProductModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={selectedProduct}
            />)}

            <BranchSelection
                show={showBranchSelection}
                onHide={() => setShowBranchSelection(false)}
                onSelectBranch={handleBranchSelect}
            />
        </>
    );
};

export default App;
